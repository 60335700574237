import React, { useState } from "react"
import { BasicMenu } from "../../../shared/ui/menu"
import { EditBtn } from "../../../shared/ui/button/EditBtn"
import { DeleteBtn } from "../../../shared/ui/button/DeleteBtn"
import { RemoveMaster } from "../../../features/masters/ui/RemoveMaster"
import { EditMaster } from "../../../features/masters/ui/EditMaster"
import { RemoveTemplate } from "../../../features/laboratory/templates/RemoveTemplate"
import { EditTemplate } from "../../../features/laboratory/templates/EditTemplate"

const isMobile = window.screen.width < 500

export const TemplateRows = ({ template, forceUpdate, templatesRefetch }) => {
  const [activeEdit, setActiveEdit] = useState(false)
  const [activeRemove, setActiveRemove] = useState(false)

  function edit() {
    setActiveEdit(true)
  }

  function remove() {
    setActiveRemove(true)
  }

  return (
    <>
      {isMobile ? (
        <div className='bg-white p-3 rounded-lg mb-5 last:mb-0 flex justify-between items-center sm:hidden'>
          <div className='flex flex-col [&>p]:my-1'>
            <p className='text-[16px] text-[#1E3148]  font-medium'>
              {template.name}
            </p>
            <p className='text-xs text-darkGray'>
              Кол.показатель:{template.medical_tests.length}
            </p>
            <p className='text-xs text-darkGray'>
              Цена:{`${template.price}.00`}
            </p>
          </div>
          {/* <BasicMenu
            title={"⋮"}
            options={["Удалить", "Редактировать"]}
            chooseOption={(val) => (val === "Удалить" ? remove() : edit())}
          /> */}
          <div className='flex items-center justify-end tableBtns'>
            <EditBtn onClick={edit} />
            <DeleteBtn onClick={remove} />
          </div>
        </div>
      ) : (
        <>
          <p>{` ${template.name}`}</p>
          <p>{template.medical_tests.length}</p>
          <p>{`${template.price}`}</p>
          <div className='flex items-center justify-end tableBtns'>
            <EditBtn onClick={edit} />
            <DeleteBtn onClick={remove} />
          </div>
        </>
      )}
      {activeRemove && (
        <RemoveTemplate
          template={template}
          active={activeRemove}
          setActive={setActiveRemove}
          forceUpdate={forceUpdate}
        />
      )}

      {activeEdit && (
        <EditTemplate
          template={template}
          active={activeEdit}
          setActive={setActiveEdit}
          forceUpdate={forceUpdate}
        />
      )}
    </>
  )
}