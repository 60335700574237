import { createApi } from "@reduxjs/toolkit/dist/query/react"

import { baseQueryWithReauth, getApiConfig } from "shared/api"

const DEFAULT_AVATAR_IMAGE =
  "//static.vecteezy.com/system/resources/previews/005/276/776/original/logo-icon-person-on-white-background-free-vector.jpg"
const { BASE_URL, prepareHeaders, accessToken } = getApiConfig()
const MASTERS_PATH = "master/specialists"
const WORKTIME_PATH = "master/masters-and-salon"

function formatPhone(phone) {
  // take only numbers
  return phone.match(/[0-9]/g).join("")
}

export function reformatPhone(phone) {
  // "987654321" => "+992-987-654-321"
  const newPhoneArr = []
  for (let i = 0; i + 2 < phone.split("").length; i += 3) {
    newPhoneArr.push(phone[i] + phone[i + 1] + phone[i + 2])
  }
  if (newPhoneArr[0] !== "+992") {
    newPhoneArr.unshift("+992")
    return newPhoneArr.join("-")
  }
  return newPhoneArr.join("-")
}



export const mastersApi = createApi({
  reducerPath: "mastersApi",
  tagTypes: ["Masters", "WorktimeMasters"],
  baseQuery: baseQueryWithReauth({ baseUrl: BASE_URL, prepareHeaders }),
  endpoints: (builder) => ({
    getMasters: builder.query({
      query: ({ page = 1, perPage = 10, limit }) => {
        let url = `/${MASTERS_PATH}?page=${page}&perPage=${perPage}`
        if (limit) {
          url += `&limit=${limit}`
        }
        return url
      },
      providesTags: ({ data }) => {
        if (data) {
          return [
            ...data.data.map(({ id }) => ({ type: "Masters", id })),
            { type: "Masters", id: "LIST" },
          ]
        }
        return [{ type: "Masters", id: "LIST" }]
      },
    }),
    getCaledarMasters: builder.query({
      query: () => `/${MASTERS_PATH}?limit=1000`,
      providesTags: ({ data }) => {
        if (data) {
          return [
            ...data.data.map(({ id }) => ({ type: "Masters", id })),
            { type: "Masters", id: "LIST" },
          ]
        }
        return [{ type: "Masters", id: "LIST" }]
      },
    }),
    searchMasters: builder.query({
      query: (search) => `master/search/specialists?keyword=${search}`,
      invalidatesTags: [
        { type: "Masters", id: "LIST" },
        { type: "WorktimeMasters", id: "LIST" },
      ],
    }),
    postMaster: builder.mutation({
      query: ({
        name,
        surname,
        phone,
        email,
        position,
        gender,
        description,
        show_calendar,
        website,
        instagram,
        facebook,
        work_schedules,
        services,
        role_id,
        bonus,
        phone_hide,
      }) => ({
        url: `/${MASTERS_PATH}`,
        method: "POST",
        body: {
          name,
          surname,
          email,
          phone: formatPhone(phone),
          gender,
          position,
          description,
          show_calendar: +show_calendar,
          website,
          instagram,
          facebook,
          work_schedules,
          role_id,
          bonus,
          phone_hide: +phone_hide,
          // work_schedules:work_schedules?.map(({id})=>id),

          services: services?.map(({ id }) => id),
        },
      }),
      invalidatesTags: [
        { type: "Masters", id: "LIST" },
        { type: "WorktimeMasters", id: "LIST" },
      ],
    }),
    updateMaster: builder.mutation({
      query: ({
        name,
        id,
        surname,
        phone,
        email,
        position,
        gender,
        description,
        show_calendar,
        website,
        instagram,
        facebook,
        work_schedules,
        services,
        role_id,
        bonus,
        phone_hide,
      }) => ({
        url: `/${MASTERS_PATH}/${id}`,
        method: "PATCH",
        body: {
          name,
          surname,
          email,
          phone: formatPhone(phone),
          gender,
          position,
          description,
          show_calendar: +show_calendar,
          website,
          instagram,
          facebook,
          work_schedules,
          role_id,
          bonus,
          phone_hide: +phone_hide,
          services: services?.map(({ id }) => id),
        },
      }),
      invalidatesTags: [
        { type: "Masters", id: "LIST" },
        { type: "WorktimeMasters", id: "LIST" },
      ],
    }),
    deleteMaster: builder.mutation({
      query: (masterId) => ({
        url: `/${MASTERS_PATH}/${masterId}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: "Masters", id: "LIST" },
        { type: "WorktimeMasters", id: "LIST" },
      ],
    }),
    deleteMasterService: builder.mutation({
      query: ({ masterId, id }) => ({
        url: `/${MASTERS_PATH}/${masterId}/services/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        { type: "Masters", id: "LIST" },
        { type: "WorktimeMasters", id: "LIST" },
      ],
    }),

    // Worktime services
    getWorktimeMasters: builder.query({
      query: () => `/${WORKTIME_PATH}`,
      providesTags: ({ data }) => {
        if (data) {
          return [
            ...data.map(({ id }) => ({ type: "WorktimeMasters", id })),
            { type: "WorktimeMasters", id: "LIST" },
          ]
        }
        return [{ type: "WorktimeMasters", id: "LIST" }]
      },
      transformResponse: ({ data }) =>
        data.map((master) => ({
          ...master,
          avatar: {
            id: master.avatar.length ? master.avatar[0].id : null,
            original_url: master.avatar.length
              ? master.avatar[0].original_url
              : DEFAULT_AVATAR_IMAGE,
          },
        })),
    }),
  }),
})

function photoPrepareHeaders(headers) {
  headers.set("Authorization", `Bearer ${accessToken}`)
  headers.set("Accept", "application/json")
  return headers
}

export const photoApi = createApi({
  reducerPath: "photoApi",
  baseQuery: baseQueryWithReauth({
    baseUrl: BASE_URL,
    prepareHeaders: photoPrepareHeaders,
  }),
  endpoints: (builder) => ({
    postAvatar: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/${MASTERS_PATH}/${id}/update-image`,
        method: "POST",
        body: body.formData,
      }),
    }),

    postPortfolio: builder.mutation({
      query: (file) => {
        const formdata = new FormData()
        formdata.append("collection_name", "portfolio")
        formdata.append("file", file)
        return {
          url: `/${MASTERS_PATH}/update-image`,
          method: "POST",
          body: formdata,
        }
      },
    }),
  }),
})

export const {
  useGetMastersQuery,
  useGetCaledarMastersQuery,
  usePostMasterMutation,
  useUpdateMasterMutation,
  useDeleteMasterMutation,
  useGetWorktimeMastersQuery,
  useSearchMastersQuery,
  useDeleteMasterServiceMutation,
} = mastersApi
export const { usePostAvatarMutation, usePostPortfolioMutation } = photoApi