export const useConvertToMinutes = () => {
  const convert = (time) => {
    const [hours, minutes, seconds] = time.split(":").map(Number)
    return hours * 60 + minutes + Math.floor(seconds / 60)
  }
  return convert
}

export const useFormatDate = () => {
  const formatDate = (date) => {
    const newDate = new Date(date)
    const year = newDate.getFullYear()
    const month = (newDate.getMonth() + 1).toString().padStart(2, "0") // +1, так как месяцы начинаются с 0
    const day = newDate.getDate().toString().padStart(2, "0")

    return `${year}-${month}-${day}`
  }

  return formatDate
}