import React from "react"
import dayjs from "dayjs"

export function MonthDateHeader({ props, date, worktime }) {
  const { label } = props

  const isWeekend = ["Su", "Sa"].includes(dayjs(props.date).format("dd"))

  // if date 1st char === 0, then show only 2nd char (01-09 === 1-9)
  const formattedLabel = label.charAt(0) === "0" ? label.charAt(1) : label

  // day off during the week
  const isDayOff = worktime?.some(
    ({ start, dayOff }) =>
      props.date.toLocaleDateString() === start.toLocaleDateString() && dayOff
  )
  const isToday =
    props.date.toLocaleDateString() === new Date().toLocaleDateString()

  return (
    <div
      data-day={props.date.toLocaleDateString()}
      className='relative w-fit h-fit'
    >
      <p
        className={`
          ${/* current day */ ""}
          ${
            isToday
              ? `
              bg-orange !text-white absolute -top-2 sm:-top-3 -right-[0.9px] sm:right-0 px-1 py-[2px] sm:px-[6px] sm:py-[2px] rounded-lg
            `
              : "mt-1 mr-[-1px] sm:mt-2 sm:mr-2"
          }
           text-[10px] sm:text-base z-10 
        `}
      >
        <span
          className={`
            ${(isDayOff || isWeekend) && !isToday && "text-red"}
            ${/* prev/next months weekends */ ""}
            ${
              props.isOffRange &&
              (isWeekend ? "text-red/20" : !isToday && "text-gray")
            }`}
        >
          {formattedLabel}
        </span>
      </p>
    </div>
  )
}