import { IconButton, InputAdornment } from "@mui/material"
import React, { useEffect, useState } from "react"
import { MyController } from "./MyController"
import { InputBase } from "shared/ui/input/InputBase"
import { ContentCopy } from "@mui/icons-material"
import { toast } from "sonner"

const DeepLinkRedirect = ({ deepLink }) => {
  const [value, setValue] = useState(`https://navbat.tj/${deepLink || ""} `)

  useEffect(() => {
    setValue(`https://navbat.tj/${deepLink || ""}`)
  }, [deepLink])

  const handleCopy = () => {
    navigator.clipboard.writeText(`https://navbat.tj/${deepLink || ""} `)
    toast.success("Ссылка скопирована!")
  }

  return (
    <div className='inline-block w-full'>
      <div className='relative'>
        <input
          type='text'
          value={value}
          readOnly
          onChange={(e) => setValue(e.target.value)}
          className='w-full px-4 py-2 bg-[#EDEDED]  rounded-lg cursor-pointer pr-12 outline-none'
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        />
        <InputAdornment
          position='end'
          className='absolute right-2 top-1/2 transform -translate-y-1/2'
        >
          <IconButton onClick={handleCopy}>
            <ContentCopy style={{ fontSize: "1.7rem" }} />
          </IconButton>
        </InputAdornment>
      </div>
    </div>
  )
}

export default DeepLinkRedirect;
