import React from "react"
export const DataTable = ({ titles, children, className }) => {
  const length = titles.length
  return (
    <>
      {/* titles */}
      <div
        className={`${className} grid ${
          "grid-cols-" + length
        } py-4 text-lg sm:[&>p]:pr-2`}
      >
        {titles.map((item, index) => (
          <p
            key={index}
            className='last:text-end whitespace-nowrap sm:overflow-hidden text-ellipsis w-full'
          >
            {item}
          </p>
        ))}
      </div>

      {/* body */}
      {children}
    </>
  )
}
