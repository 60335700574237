import { createAsyncThunk } from "@reduxjs/toolkit"
import dayjs from "dayjs"

import { createApi } from "@reduxjs/toolkit/dist/query/react"

import { baseQueryWithReauth, getApiConfig } from "shared/api"

const { BASE_URL, prepareHeaders } = getApiConfig()
const BOOKINGS_PATH = "master/bookings"

function transformGetBookingsResponse({ data }) {
  return data?.map((item) => ({
    id: item?.id,
    master_id: item?.master?.id,
    status_id: item?.status?.id,
    service_id: 0,
    name: item?.client?.name,
    duration: "00:40",
    price: "200",
    booking_id: item?.id,
    phone: item?.client?.phone,
    surname: item?.client?.surname,
    startDate: dayjs(`${item?.start}`).toDate(),
    endDate: dayjs(new Date(`${item?.end}`)).toDate(),
    services: [item?.services],
    color:
      item?.status?.id === 3
        ? "#EDF1F7"
        : item?.status?.id === 4
          ? "#FFF5DD"
          : item?.status?.id === 6
            ? "#D5F5CC"
            : "",

    master_name: item?.master?.name,
    master_surname: item?.master?.surname,
  }))
}

export const bookingsApi = createApi({
  reducerPath: "bookingsApi",
  tagTypes: ["Bookings"],
  baseQuery: baseQueryWithReauth({ baseUrl: BASE_URL, prepareHeaders }),
  endpoints: (builder) => ({
    //get all
    getBookings: builder.query({
      query: () => `/${BOOKINGS_PATH}`,
      providesTags: ({ data }) => {
        if (data) {
          return [
            ...data.map(({ id }) => ({ type: "Bookings", id })),
            { type: "Bookings", id: "LIST" },
          ]
        }
        return [{ type: "Bookings", id: "LIST" }]
      },
      transformResponse: transformGetBookingsResponse,
    }),
    getAllBookings: builder.query({
   query: (params) => `/master/search/table?keyword=${params.search || ''}&start_date_from=${params.startDate || ''}&start_date_to=${params.endDate || ''}`, providesTags: ({ data }) => {
        if (data) {
          return [
            ...data?.map(({ id }) => ({ type: "Bookings", id })),
            { type: "Bookings", id: "LIST" },
          ]
        }
        return [{ type: "Bookings", id: "LIST" }]
      },
      transformResponse: transformGetBookingsResponse,
    }),
    postBooking: builder.mutation({
      query: (newBooking) => ({
        url: `master/bookings`,
        method: "POST",
        body: newBooking,
      }),
      invalidatesTags: [{ type: "Bookings", id: "LIST" }]
    }),
    updateBooking: builder.mutation({
      query: ({ id, status_id }) => ({
        url: `master/bookings/${id}`,
        method: "PATCH",
        body: { status_id: status_id },
      }),
      invalidatesTags: [{ type: "Bookings", id: "LIST" }]
    }),
    deleteBooking: builder.mutation({
      query: (id) => ({
        url: `master/bookings/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Bookings", id: "LIST" }]
    })
  }),
})

export const { useGetBookingsQuery,   useGetAllBookingsQuery, usePostBookingMutation, useDeleteBookingMutation, useUpdateBookingMutation } = bookingsApi
// post new Booking
export const postBooking = createAsyncThunk(
  "bookings/post",
  async (newBooking, thunkApi) => {
    const STATUS_SUCCESS = "Запись успешно создана";
    const STATUS_FAILED = "Что-то пошло не так...";
    try {
      const res = await bookingsApi.postBooking(
        // {
        //   date: "2023-02-21",
        //   time: "10:00",
        //   user_id: 1,
        //   service_id: 1,
        //   master_id: 1,
        // }
        newBooking
      );
      if (res.statusText) return STATUS_SUCCESS;
    } catch (error) {
      // если надо будет показывать получаемую ошибку
      const errorMessage = error.response.data.message;
      // сейчас сообщение об ошибке для всех случаев одно
      return thunkApi.rejectWithValue(STATUS_FAILED);
    }
  }
);

export const getBooking = createAsyncThunk(
  "bookings/get",
  async (newBooking, thunkApi) => {
    try {
      const res = await bookingsApi.getAllBookings(
        newBooking
      );
      return res;
    } catch (error) {
      const errorMessage = error.response.data.message;
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

