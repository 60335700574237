import React,{ memo, useEffect, useState } from "react"
import { Close } from "@mui/icons-material"
import { Modal } from "shared/ui/modal/Modal"

const ChangeStatus = ({
  active,
  setActive,
  onSubmit,
  text,
  id,
  loading,
  onChangeStatus,
  onClose,
  onConfirm,
  closeStatusModal,
  isSuccess,
  isError,
  successLabel,
  errorLabel,
}) => {
  const [success, setSuccessActive] = useState(false)
  const [error, setError] = useState(false)
  const [clicked, setClicked] = useState(false)
  const handleConfirm = async () => {
    if (!clicked && !loading) {
      setClicked(true)
      await onConfirm()
    }
  }

  useEffect(() => {
    if (isSuccess) {
      setSuccessActive(true)
    }
  }, [isSuccess])
  useEffect(() => {
    if (isError) {
      setError(true)
    }
  }, [isError])

  const handleClick = () => {
    onClose()
    window.location.reload()
  }
  return (
    <>
      <Modal
        active={active}
        setActive={setActive}
        className={`max-w-sm ${success ? "hidden" : "block"}`}
      >
        <div className='text-end'>
          <button
            onClick={onClose}
            className='text-gray hover:text-black h-fit'
          >
            <Close />
          </button>
        </div>
        <p className='text-darkGray text-center max-w-[250px] mx-[auto] my-5'>
          {text}
        </p>
        <div className='flex'>
          <button className='btnOutlined mr-5 flex-1' onClick={onClose}>
            Отмена
          </button>
          <button
            className={`btnFilled flex-1 ${loading || clicked ? "opacity-50" : "opacity-100"}`}
            disabled={loading || clicked}
            onClick={handleConfirm}
          >
            Да
          </button>
        </div>
      </Modal>

      {success && (
        <Modal
          active={success}
          className='max-w-sm  flex flex-col h-[170px] justify-between'
        >
          <p className='text-darkGray text-center max-w-[250px] mx-[auto] my-5'>
            {successLabel}
          </p>
          <div className=' w-full'>
            <button
              className=' w-full text-right text-sm font-medium  '
              onClick={handleClick}
            >
              Ок
            </button>
          </div>
        </Modal>
      )}
      {error && (
        <Modal
          active={success}
          className='max-w-sm  flex flex-col h-[170px] justify-between'
        >
          <p className='text-darkGray text-center max-w-[250px] mx-[auto] my-5'>
            {errorLabel}
          </p>
          <div className=' w-full'>
            <button
              className=' w-full text-right text-sm font-medium  '
              onClick={handleClick}
            >
              Ок
            </button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default memo(ChangeStatus);
