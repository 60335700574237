import { types } from "mobx-state-tree"
import { BasePageStore } from "./BasePageStore"

export const NotificationsPageStore = types
  .compose(
    BasePageStore,
    types.model("NotificationsPageStore", {
      data: types.string,
    })
  )
  .views((self) => ({
    get query() {
      return {
        page: self.currentPage,
        per_page: self.rowsPerPage,
        search: self.searchString,
      }
    },
  }))