import React from "react"
import { useState } from "react"
import { Close } from "@mui/icons-material"
import ReactInputMask from "react-input-mask"
import { useFormik } from "formik"
import * as yup from "yup"

import { schemes } from "widgets/auth/validations"
import { Modal } from "shared/ui/modal/Modal"
import { ErrorMsg } from "shared/ui/input/ErrorMsg"
import { PhoneInput } from "shared/ui/input/PhoneInput"

export function ChangePhone({ active, setActive }) {
  const [status, setStatus] = useState(null)

  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      phone: "",
      code: "",
      newPhone: "",
    },
    validationSchema: yup.object().shape({
      phone: schemes.phone,
      code: schemes.code,
      newPhone: schemes.phone,
    }),
    onSubmit: (val) => {
      setActive(false)
    },
  })

  function sendConfirmationCode() {
    // if(values.tel.length === 0) handleSubmit()
    // else {
    setStatus("sending")
    setTimeout(() => {
      setStatus("sent")
    }, 2000)
    // }
  }

  function checkCode() {
    // if(errors.code) handleSubmit()
    // else
    setStatus("checking")
    setTimeout(() => {
      setStatus("checked")
    }, 2000)
  }

  const [isNewPhoneValid, setIsNewPhoneValid] = useState(true)

  function saveNewTel() {
    // if(!values.newTel || (values.newTel.length === 16 && !Number(values.newTel[values.newTel.length - 1]))) setIsNewTelValid(false)
    // else {
    setIsNewPhoneValid(true)
    setStatus("saving")
    setTimeout(() => {
      handleSubmit()
    }, 2000)
    // }
  }

  return (
    <Modal active={active} setActive={setActive} className={"max-w-sm"}>
      <div>
        <div className='text-end'>
          <button
            className='text-gray hover:text-black'
            onClick={() => setActive(false)}
          >
            <Close />
          </button>
        </div>
        {[null, "sending"].includes(status) ? (
          <div>
            <h2 className='title'>Подтвердите что это вы</h2>
            <p className='mb-5 mt-3 text-sm text-darkGray font-normal leading-[1.2]'>
              Введите старый номер телефона для подтверждения личности
            </p>
            <label className='label-base relative'>
              Номер телефона
              <PhoneInput
                name={"phone"}
                className='w-full'
                value={values.phone}
                onChange={handleChange}
                disabled={status === "sending"}
              />
              {errors.phone && touched.phone && (
                <ErrorMsg title={errors.phone} className='-bottom-9' />
              )}
            </label>
            <button
              type='submit'
              onClick={sendConfirmationCode}
              className='disabled:bg-gray btnFilled w-full mt-10'
            >
              {status === "sending" ? "Отправляем код..." : "Далее"}
            </button>
          </div>
        ) : ["sent", "checking"].includes(status) ? (
          <div>
            <h2 className='title'>Код подтверждения</h2>
            <p className='mb-5 mt-3 text-sm text-darkGray font-normal leading-[1.2]'>
              На ваш номер отправлен код подтверждения
            </p>
            <label className='label-base relative'>
              Код подтверждения
              <ReactInputMask
                mask={"9999"}
                maskPlaceholder=''
                type='text'
                onKeyDown={(e) =>
                  e.code === "Tab"
                    ? e.preventDefault()
                    : e.code === "Enter" &&
                      values.code.length === 5 &&
                      handleSubmit()
                }
                name='code'
                placeholder='----'
                value={values.code}
                onChange={handleChange}
                className='inputOutlined w-full tracking-[5px]'
              />
              {touched.code && errors.code && (
                <ErrorMsg title={errors.code} className='-bottom-9' />
              )}
            </label>
            <button
              type='submit'
              onClick={checkCode}
              className='disabled:bg-gray btnFilled w-full mt-10'
            >
              {status === "checking" ? "Проверяем код..." : "Далее"}
            </button>
          </div>
        ) : (
          <div>
            <h2 className='title'>Новый номер телефона</h2>
            <p className='mb-5 mt-3 text-sm text-darkGray font-normal leading-[1.2]'>
              Введите свой новый номер телефона
            </p>
            <label className='label-base relative'>
              Номер телефона
              <PhoneInput
                name={"newPhone"}
                className='w-full'
                value={values.newPhone}
                onChange={handleChange}
                disabled={status === "saving"}
              />
              {!isNewPhoneValid && (
                <ErrorMsg
                  title={"Заполните поле полностью"}
                  className='-bottom-9'
                />
              )}
            </label>
            <button
              onClick={saveNewTel}
              className='disabled:bg-gray btnFilled w-full mt-10'
            >
              {status === "saving" ? "Сохраняем новый номер..." : "Сохранить"}
            </button>
          </div>
        )}
      </div>
    </Modal>
  )
}