import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { hashUrl } from "url"

const baseUrl = hashUrl

export const bookingHashApi = createApi({
  reducerPath: "bookingHashApi",
  // baseQuery: fetchBaseQuery({ baseUrl }),
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      // headers.set("Navbat-key", `kEX9oFXFkv0GGiOdPO55xgcZ4pAErUzNOoej3kwr`)
      headers.set('Navbat-key', `YpCCMBnHLsK0bz6agS7lEn4UMfKQTA8jOJjNLk38`);

      return headers
    },
  }),
  endpoints: (builder) => ({
    getBookingByHash: builder.query({
      query: (hash) => `client/v1/bookings/${hash}`,
    }),
    getAvailableTimeSlots: builder.query({
      query: ({ bookingId, hash, masterId, serviceDuration, date }) =>
        `v1/master/bookings/getAvailableTimeSlotsWithHash/${bookingId}/${hash}/${masterId}/${serviceDuration}/${date}`,
    }),
    updateHashBooking: builder.mutation({
      query: ({ body, bookingId, hash }) => ({
        url: `v1/master/booking/update-with-hash/${bookingId}/${hash}`,
        method: "PATCH",
        body,
      }),
    }),
  }),
})


export const { useGetBookingByHashQuery, useGetAvailableTimeSlotsQuery, useUpdateHashBookingMutation } = bookingHashApi;
