import React, { useState } from "react"

export const OneTemplateRow = ({
  id,
  index,
  name,
  result,
  norm,
  unit,
  length,
}) => {
  const [currentResult, setCurrentResult] = useState("")
  return (
    <div
      className={`flex items-center justify-center w-full px-7 border-b border-x py-5 border-[#EDEDED] ${
        index === length - 1 && "rounded-b-[8px]"
      }`}
    >
      <input
        className='border-none outline-none disabled w-full whitespace-nowrap font-medium text-[14px]'
        defaultValue={name}
      />
      <input
        value={currentResult}
        onChange={(e) => setCurrentResult(e.target.value)}
        defaultValue={result}
        className='inputOutlined w-[160px] mr-8'
      />
      <input
        className='border-none outline-none disabled w-full whitespace-nowrap font-medium text-[14px]'
        defaultValue={norm}
      />
      <input
        className='border-none outline-none disabled w-full whitespace-nowrap font-medium text-[14px]'
        defaultValue={unit}
      />
    </div>
  )
}

