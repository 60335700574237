import React from "react"
import { Modal } from "@mui/material"
import { DeleteBtn } from "../../../../shared/ui/button/DeleteBtn"
import html2canvas from "html2canvas"
import { jsPDF } from "jspdf"

function getCurrentDateTime() {
  const now = new Date()

  const day = String(now.getDate()).padStart(2, "0")
  const month = String(now.getMonth() + 1).padStart(2, "0") // Месяцы начинаются с 0
  const year = now.getFullYear()

  const hours = String(now.getHours()).padStart(2, "0")
  const minutes = String(now.getMinutes()).padStart(2, "0")

  return `${day}.${month}.${year} ${hours}:${minutes}`
}

export const TemplateModal = ({ open, setOpen, items, data, name }) => {
  const {report} = data
  const openPdfInNewTab = (pdf) => {
    // Преобразование документа jsPDF в blob
    const pdfBlob = pdf.output('blob');

    // Создание URL для blob-объекта
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Открытие PDF в новой вкладке
    window.open(pdfUrl, '_blank');
  };
  const generatePdf = async () => {
    const content = document.getElementById("template_pdf");
    const canvas = await html2canvas(content, {
      scale: 1.5, // Увеличить качество изображения
      useCORS: true, // Если изображения загружаются с других доменов
    });
    const imgData = canvas.toDataURL("image/png");
    const pdfWidth = canvas.height * 2; // Уменьшение размера изображения
    const pdfHeight = canvas.height;

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [pdfWidth, pdfHeight]
    });
    pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
    openPdfInNewTab(pdf);
  };
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="w-[900px] h-full bg-white mx-auto mt-4 pb-10 overflow-y-auto">
        <div id={"template_pdf"} className="min-h-[900px] w-full pt-12 px-12 pb-5 bg-white overflow-y-auto">
          <div className="text-start font-medium text-[20px]">
            <p>"Дента"</p>
            <p>г. Душанбе, ул. Н.Карабаева 8/1</p>
            <p>тел: +992 000000000</p>
            <p>{getCurrentDateTime()}</p>
          </div>
          <div className="flex items-center justify-start w-full mt-6 gap-[200px]">
            <div className="flex flex-col items-start justify-center gap-1">
              <p className="text-[#737C86] text-[12px] font-medium">ID анализа</p>
              <p>{report.id}</p>
            </div>
            <div className="flex flex-col items-start justify-center gap-1">
              <p className="text-[#737C86] text-[12px] font-medium">ФИО</p>
              <p>{report.surname} {report.name} {report.patronymic || ''}</p>
            </div>
            <div className="flex flex-col items-start justify-center gap-1">
              <p className="text-[#737C86] text-[12px] font-medium">Дата рождения</p>
              <p>{report.birthday}</p>
            </div>
          </div>
          <h1 className="text-[32px] font-medium text-center mt-8">{name}</h1>
          <div className="flex items-center justify-start gap-10 w-full mt-10 px-7 border rounded-t-[8px] border-lightGray py-3">
            <p className="w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[12px]">Название показателя</p>
            <p className="w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[12px]">Результат</p>
            <p className="w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[12px]">Норма</p>
            <p className="w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[12px]">Eд.измер</p>
          </div>
          <div
            className="w-full flex flex-col gap-2 border-x border-b border-lightGray  overflow-y-auto">
            {items.map(item =>
              <div key={item.id} className="w-full flex items-center border-b border-lightGray justify-between py-4">

              <p className={`text-[14px] px-7 w-[26%]`}>{item.name}</p>
              <p className={`text-[14px] px-7 w-[25%]`}>{item.result}</p>
              <p className={`text-[14px] px-7 w-[25%]`}>{item.norm}</p>
              <p className={`text-[14px] px-7 w-[25%]`}>{item.unit}</p>
              <p></p>

            </div>)}
          </div>
        </div>
        <div className='flex items-center justify-center'>
          <button className='btnFilled' type={'button'} onClick={generatePdf}>Распечатать</button>
        </div>
      </div>
    </Modal>
  )
}

