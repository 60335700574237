import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { currentUrl } from "url"

// Определение базового URL
// const baseUrl = 'https://test.api.navbat.tj/api/';
const baseUrl = currentUrl
const accessToken = localStorage.getItem("accessToken")
// Создание API
export const clientApi = createApi({
  reducerPath: "clientApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      if (accessToken) {
        headers.set("Authorization", `${accessToken}`)
        headers.set("Accept", "application/json")
        // headers.set('Content-type', 'x-www-form-urlencoded')
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    updateClient: builder.mutation({
      query: (clientData) => ({
        url: `/master/clients/${clientData.id}`,
        method: "PUT",
        body: clientData,
      }),
    }),
    getClientBookingHistory: builder.query({
      query: ({ id, page = 1, perPage = 10, limit }) => ({
        url: `/master/clients/history/${id}`,
        method: "GET",
        params: { page, perPage, limit },
      }),
    }),
    getClientHistoryRecords: builder.query({
      query: (id) => ({
        url: `/master/clients/records/${id}`,
        method: "GET",
      }),
    }),
  }),
})


export const {
  useUpdateClientMutation,
  useGetClientBookingHistoryQuery,
  useGetClientHistoryRecordsQuery,
} = clientApi;
