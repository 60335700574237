import React from "react"
import { useState } from "react"
import { MenuItem } from "@mui/material"

import { SelectContained } from "shared/ui/select/SelectContained"
import { GoBackBtn } from "../../../../widgets/auth/GoBackBtn"
import { useGetBusinessCategoriesQuery } from "pages/categories/slice"
import { useEffect } from "react"
import { ErrorMsg } from "shared/ui/input/ErrorMsg"
import { useGetBusinessSubCategoriesQuery } from "../../../categories/slice"

export function SelectBusinessCategory({
  submit,
  backSlide,
  errorMsg,
  hidden4,
}) {
  const [direction, setDirection] = useState("")
  const [subCategory, setSubCategory] = useState("")
  const [error, setError] = useState("")
  const { data: businessCategories } = useGetBusinessCategoriesQuery()
  const { data: businessSubCategories } = useGetBusinessSubCategoriesQuery(
    direction,
    {
      skip: !direction, // Запрос не отправляется, если direction пуст
    }
  )

  useEffect(() => {
    if (businessCategories && businessCategories.length > 0) {
      const firstDirectionId = businessCategories[0]?.direction?.id
      if (firstDirectionId) setDirection(firstDirectionId)

    }
  }, [businessCategories])

  useEffect(() => {
    if (businessSubCategories && businessSubCategories.length > 0) {
      const firstSubCategoryId = businessSubCategories[0]?.id
      if (firstSubCategoryId) setSubCategory(firstSubCategoryId)
    }
  }, [businessSubCategories])

  const handleCategory = (event) => {
    const selectedDirectionId = event.target.value
    setDirection(selectedDirectionId) // Устанавливаем категорию
    setSubCategory("")
    setError("") // Сбрасываем подкатегорию при смене категории
  }

  const handleSubCategoryChange = (event) => {
    setSubCategory(event.target.value)
    setError("") // Устанавливаем подкатегорию
  }
  const onSubmit = () => {
    if (!direction || !subCategory) {
      setError("Выберите категорию и подкатегорию")
      return
    }

    submit({
      direction_id: direction,
      category_id: subCategory,
    })
  }

  const [timer, setTimer] = useState(0)

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1)
      }, 1000)
    }
  }, [timer])
  console.log("businessSubCategories", businessSubCategories)
  console.log("businessCategories", businessCategories)

  return (
    <div
      className={`max-w-[344px] w-full relative mt-[10px] sm:mt-0 ${
        hidden4 && "hidden"
      }`}
    >
      <div className='text-center mx-auto sm:mb-12'>
        <h1 className='text-[27px] font-medium mb-6'>
          Выберите сферу деятельности
        </h1>
      </div>
      <div className='flex flex-col  sm:gap-0'>
        <label className='label-base w-full text-left [&>div]:mt-1'>
          Сфера деятельности
          <SelectContained value={direction} onChange={handleCategory}>
            <MenuItem
              sx={{ color: "primary.main" }}
              value=''
              className='hidden'
            >
              Выберите раздел услуг
            </MenuItem>

            {businessCategories?.map((item, index) => (
              <MenuItem
                key={index}
                sx={{ color: "primary.main" }}
                value={item?.direction?.id}
              >
                {item?.direction?.name}
              </MenuItem>
            ))}
          </SelectContained>
        </label>
        <lebel className='label-base w-full text-left [&>div]:mt-1 mt-4'>
          Ваши услуги
          <SelectContained
            value={subCategory}
            onChange={handleSubCategoryChange}
          >
            <MenuItem
              sx={{ color: "primary.main" }}
              value=''
              className='hidden'
            >
              Выберите раздел услуг
            </MenuItem>

            {businessSubCategories?.data?.map((item, index) => (
              <MenuItem
                key={index}
                sx={{ color: "primary.main" }}
                value={item?.id}
              >
                {item?.name}
              </MenuItem>
            ))}
          </SelectContained>
        </lebel>
        <div>
          <button
            disabled={timer > 0}
            className='btnFilled w-full mt-8'
            onClick={onSubmit}
          >
            Далее
          </button>
          <GoBackBtn handleClick={backSlide} />
        </div>
      </div>
      {errorMsg && (
        <ErrorMsg title={errorMsg} className='-bottom-10 text-center' />
      )}
    </div>
  )
}