import React from "react"
import { DataTable as TableTitles } from "../../../shared/ui/dataTable"
import { AnalyzesRows } from "./AnalyzesRows"

const titles = [
  "ID анализа",
  "Ф.И.О",
  "Дата",
  "Номер телефона",
  "Сумма",
  "Результат",
  "Статус оплаты",
  "",
]

const AnalyzesTable = ({ analyzes }) => {
  return (
    <>
      <TableTitles
        className='sm:grid sm:grid-cols-8 hidden gap-3 whitespace-nowrap '
        titles={titles}
      />
      {analyzes?.map((analyze) => (
        <div
          key={analyze?.id}
          className={`sm:grid-cols-1 items-center w-full tableRows sm:grid`}
        >
          <AnalyzesRows analyze={analyze} titles={titles} />
          <span className='tableRowLine col-span-1'></span>
        </div>
      ))}
      {/*<div>*/}
      {/*  <PaginationBlock*/}
      {/*    setRowsPerPage={bookingsPageStore.setRowsPerPage}*/}
      {/*    setPage={bookingsPageStore.setCurrentPage}*/}
      {/*    currentPage={bookingsPageStore.currentPage}*/}
      {/*    pagesCount={bookingsPageStore.numberOfPages}*/}
      {/*    perPage={bookingsPageStore.rowsPerPage}*/}
      {/*  />*/}
      {/*</div>*/}
    </>
  )
}

export default AnalyzesTable
