import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getApiConfig } from "shared/api"
import { currentUrl } from "../../url"

const { accessToken } = getApiConfig()

type City = {
  id: number
  country_id: number
  name: string
}

type CitiesState = {
  cities: City[]
  citiesLoading: boolean
  citiesError: boolean
}

export const fetchCities = createAsyncThunk<
  City[],
  undefined,
  { rejectValue: boolean }
>("cities/fetchCities", async function (_, { rejectWithValue }) {
  try {

     if (!accessToken) {
      return rejectWithValue(true); // Если токена нет, возвращаем ошибку
    }

    const res = await fetch(`${currentUrl}/master/cities`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    if (!res.ok) {
      return rejectWithValue(true); // Если запрос завершился с ошибкой
    }
    const data = await res.json()

    const processedCities: City[] = data.map(
      (item: { id: number; country_id: number; name: string }): City => ({
        id: item.id,
        country_id: item.country_id,
        name: item.name,
      })
    )

    return processedCities
  }catch (err) {
    return rejectWithValue(true); // Ловим все исключения
  }
})

const initialState: CitiesState = {
  cities: [],
  citiesError: false,
  citiesLoading: true
}

const citiesSlice = createSlice({
  name: 'citiesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCities.pending, (state) => {
        state.citiesLoading = true
        state.citiesError = false
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        state.cities = action.payload
        state.citiesLoading = false
        state.citiesError = false
      })
      .addCase(fetchCities.rejected, (state) => {
        state.citiesError = true
      })
  }
})

export default citiesSlice.reducer
