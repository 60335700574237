import React from "react"
import "./style.css"

export function TableSpinner() {
  return (
    <div className='lds-ring'>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
