import React from "react"
import { DriveFileRenameOutlineOutlined } from "@mui/icons-material"

export function EditBtn({ onClick, type }) {
  return (
    <button type={type} onClick={onClick} className='w-6 h-6'>
      <DriveFileRenameOutlineOutlined
        sx={{
          color: "gray.main",
          "&:hover": { color: "primary.main" },
        }}
      />
    </button>
  )
}
