import React, { useState } from "react"
import { EditOutlined, DeleteOutlineOutlined, Close } from "@mui/icons-material"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { Modal } from "../../../../shared/ui/modal/Modal"
import { DeleteBtn } from "shared/ui/button/DeleteBtn"
import { useEditServicesMutation } from "../../../../pages/bookings/newBookingApi"

export const SelectedServiceItem = ({
  services,
  handleDeleteMaster,
  servicesFromBack,
  id,
  emptyServicesError,
  onPriceChange,
  selectedServicesAndMasters,
  setSelectedServicesAndMasters,
  editServices,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [editingService, setEditingService] = useState()
  const [newPrice, setNewPrice] = useState("")

  function formatDuration(duration) {
    const [hours, minutes, seconds] = duration.split(":").map(Number)

    // Преобразуем часы и минуты в двузначные числа
    const formattedHours = hours.toString().padStart(2, "0")
    const formattedMinutes = minutes.toString().padStart(2, "0")

    return `${formattedHours}:${formattedMinutes}`
  }

  function insertSoftHyphens(text, maxLen = 15) {
    return text
      .split(" ")
      .map((word) => {
        if (word.length > maxLen) {
          let parts = []
          for (let i = 0; i < word.length; i += maxLen) {
            parts.push(word.substring(i, i + maxLen))
          }
          return parts.join("&shy;")
        }
        return word
      })
      .join(" ")
  }
  const handleEditClick = (service) => {
    setEditingService(service)
    setNewPrice(service.price)
    setIsEditModalOpen(true)
  }

  const handleClose = () => {
    setIsEditModalOpen(false)
    setEditingService(null)
  }

  const serviceId = editingService?.service_id
  console.log(serviceId, "fffff")

  // const handleSave = async () => {
  //   if (editingService) {
  //     try {
  //       if (!id) {
  //         console.error("Ошибка: отсутствует id бронирования")
  //         return
  //       }

  //       // Приведение newPrice к числу и проверка
  //       const priceValue = Number(newPrice)
  //       if (isNaN(priceValue)) {
  //         console.error("Ошибка: newPrice не является числом", newPrice)
  //         return
  //       }

  //       await editServices({
  //         id,
  //         service_id: editingService.service_id,
  //         new_price: priceValue.toFixed(2), // Приведение к строковому формату "122.00"
  //       }).unwrap()

  //       onPriceChange(editingService.service_id, priceValue)

  //       setIsEditModalOpen(false)
  //     } catch (error) {
  //       console.error("Ошибка при сохранении данных", error)
  //     }
  //   }
  // }

  const handleSave = async () => {
    if (editingService) {
      try {
        if (!id) {
          // Если это создание записи, обновляем состояние ценников
          onPriceChange(editingService.service_id, newPrice)
        } else {
          // Если это редактирование записи, отправляем данные на сервер
          await editServices({
            id,
            service_id: editingService.service_id,
            new_price: newPrice,
          }).unwrap()
          onPriceChange(editingService.service_id, newPrice)
        }
        setIsEditModalOpen(false)
      } catch (error) {
        console.error("Ошибка при сохранении данных", error)
      }
    }
  }
  return (
    <>
      {id &&
        servicesFromBack?.map((service, index) => (
          <div
            key={`${service?.id}-${service?.service_name}`}
            className={`p-1 text-sm items-center text-center tableRows sm:grid sm:gap-3   gap-3 grid grid-cols-5 py-4 text-lg [&>p]:pr-2`}
          >
            <>
              <span className='truncate '>{service?.name}</span>
              <span>
                {service?.master_name} {service?.master_surname}{" "}
              </span>
              <span>{service?.price}</span>
              <span>{formatDuration(service?.duration)}</span>

              <Button
                variant='text'
                onClick={() => {
                  handleDeleteMaster(index)
                }}
                className='tableBtns h-full flex items-center w-fit justify-self-end'
              >
                <DeleteOutlineOutlined
                  style={{ color: "gray", fontSize: 21 }}
                />
              </Button>
            </>
            <span className='tableRowLine flex'></span>
          </div>
        ))}

      {services.map((service, index) => {
        console.log(service?.fixed_price, "service")
        return (
          <div
            key={`${service?.id}-${service?.service_name}`}
            className={`p-1 text-sm items-center text-center tableRows  sm:grid sm:gap-3 flex flex-row gap-40   border-b-2 border-[#EDEDED]  sm:border-none  sm:grid-cols-5 py-4 text-lg [&>p]:pr-2`}
          >
            <>
              <span
                className='w-[2%]  sm:w-auto  hyphens-manual sm:text-left sm:ml-[10px]  ml-[50]'
                dangerouslySetInnerHTML={{
                  __html: insertSoftHyphens(service?.service_name),
                }}
              ></span>
              <span>
                {service?.master_name} {service?.master_surname}
              </span>
              <span className='ml-6 sm:ml-[0] whitespace-nowrap'>
                {service?.fixed_price === 1 ? "от " : ""}
                {service?.price}
              </span>
              <span>{formatDuration(service?.duration)}</span>
              {!id ? (
                <>
                  <Button
                    variant='text'
                    onClick={() => handleEditClick(service)}
                  >
                    <EditOutlined style={{ color: "gray", fontSize: 21 }} />
                  </Button>
                  <Button
                    variant='text'
                    onClick={() => {
                      handleDeleteMaster(index)
                    }}
                    className='tableBtns  flex items-center w-fit justify-self-end'
                  >
                    <DeleteBtn style={{ color: "gray", fontSize: 21 }} />
                    {/* <DeleteOutlineOutlined
                  style={{ color: "gray", fontSize: 21 }}
                /> */}
                  </Button>
                </>
              ) : (
                <Button variant='text' onClick={() => handleEditClick(service)}>
                  <EditOutlined style={{ color: "gray", fontSize: 21 }} />
                </Button>
              )}
            </>
            <span className='tableRowLine flex'></span>
          </div>
        )
      })}

      <Modal
        active={isEditModalOpen}
        setActive={setIsEditModalOpen}
        className=' max-w-[328px]'
      >
        <div className=' relative  rounded-lg  '>
          <div className='flex justify-between items-start mb-6'>
            <p className=' title text-xl font-semibold mb-4'>
              Изменение услуги
            </p>
            <button
              onClick={handleClose}
              className='text-end h-fit text-gray hover:text-blue'
            >
              <Close />
            </button>
          </div>
          <div>
            <p className='text-gray-600'>
              <span className='font-normal text-xs text-[#737C86]'>
                Мастер:
              </span>{" "}
              <span className='font-medium text-[#262626] text-sm'>
                {editingService?.master_name} {editingService?.master_surname}
              </span>
            </p>
            <p className='text-gray-600'>
              <span className='font-normal text-xs text-[#737C86]'>
                Услуга:
              </span>{" "}
              <span className='font-medium text-[#262626] text-sm'>
                {editingService?.service_name}
              </span>
            </p>
            <p className='text-gray-600'>
              <span className='font-normal text-xs text-[#737C86]'>
                Длительность:
              </span>{" "}
              <span className='font-medium text-[#262626] text-sm'>
                {editingService?.duration}
              </span>
            </p>
            <label className='block text-xs text-[#737C86] font-normal mt-4'>
              Цена
            </label>
            <div className='relative mt-1'>
              <input
                className='h-12 w-full border border-[#EDEDED] rounded-lg px-3 outline-none text-[#1E3148]'
                value={newPrice}
                onChange={(e) => setNewPrice(e.target.value)}
              />
              <p className='absolute right-4 top-3 text-gray-500'>TJS</p>
            </div>
            {/* {errors?.price && (
              <span className='text-red-500 text-sm'>
                {errors?.price?.message}
              </span>
            )} */}
          </div>
          <div className='flex justify-between  mt-4'>
            <button
              onClick={handleClose}
              className=' btnOutlined  px-8 py-2 rounded-lg'
            >
              Отмена
            </button>
            <button
              onClick={handleSave}
              className={`px-6 py-2 rounded-lg bg-blue-600 text-white opacity-100 btnFilled ml-2 `}
              // disabled={reqLoading}
            >
              Сохранить
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}