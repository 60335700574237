import React from "react"

import ReactInputMask from "react-input-mask"

export function TimeInput({
  name,
  value,
  disabled,
  onChange,
  error,
  touched,
  required,
  className,
}) {
  // const otherCases = ["0", "1"].includes(value ? value[0] : "")
  // const startWithTwo = value ? value[0] === "2" : ""

  // const mask = [
  //   /[0-2]/,
  //   startWithTwo ? /[0-3]/ : otherCases && /[0-9]/,
  //   ":",
  //   /[0-5]/,
  //   /[0-9]/,
  // ]

  const mask = "99:99"
  return (
    <ReactInputMask
      mask={mask}
      placeholder={"__:__"}
      maskPlaceholder=''
      value={value}
      onChange={onChange}
      error={error}
      disabled={disabled}
      className={`${className} h-12 inputOutlined ${
        error && touched && "border-red-500 focus:border-red-500"
      }`}
    >
      <input type='text' name={name} required={required} />
    </ReactInputMask>
  )
}
