import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { currentUrl } from "../../url"

const accessToken = localStorage.getItem("accessToken")

export const financeApi = createApi({
  reducerPath: "financeApi",
  tagTypes: "Finance",
  baseQuery: fetchBaseQuery({
    baseUrl: currentUrl,
    prepareHeaders: (headers) => {
      if (accessToken) {
        headers.set("Authorization", `${accessToken}`)
        headers.set("Accept", "application/json")
      }
      return headers
    },
  }),
  endpoints: (build) => ({
    getFinances: build.query({

      query: ({ salonId, startDate, endDate, page,limit,perPage }) =>
        `/master/get-finances?salon_id=${salonId}&start_date=${startDate}&end_date=${endDate}&page=${page}&limit=${limit}&per_page=${perPage}`,
      
    }),
   
  }),
})

export const { useGetFinancesQuery } = financeApi
