import React from "react"
import { DeleteOutlined } from "@mui/icons-material"

const isMobile = window.screen.width < 500

export function DeleteBtn({ onClick, disabled }) {
  return (
    <button
      type='button'
      disabled={disabled}
      className='w-6 h-6'
      onClick={onClick}
    >
      <DeleteOutlined
        fontSize={`${isMobile ? "small" : "medium"}`}
        sx={{
          color: "gray.main",
          "&:hover": { color: "primary.main" },
        }}
      />
    </button>
  )
}
