import React from "react"
export const MonthHeader = ({ date, label }) => {
  return (
    <div
      className={`text-xs overflow-hidden sm:text-sm p-2 sm:p-4 cursor-default capitalize text-blue font-medium ${
        label === "вс" && "text-orange"
      }`}
    >
      <p>{label}</p>
    </div>
  )
}