import { currentUrl } from '../../url';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { toast } from "sonner"
const userData  = localStorage.getItem("user" )
const user = userData ? JSON.parse(userData) : {}; 
const step = user?.step
const id = user?.id
const accessToken = localStorage.getItem("accessToken")
//   ? localStorage.getItem("salon_id")
const restaurant_id = localStorage.getItem("salon_id")
  ? localStorage.getItem("restaurant_id")
  : user?.restaurant_id

const salon_id = user?.salon_id || user?.restaurant_id
const isRestaurant = user?.restaurant?.direction_id === 6

interface IMedia {
  original_url: string
  collection_name: string
}

type Setting = {
  id: number
  name: string
  surname: string
  phone: string
  deep_link: string
  description: string
  street: string
  building: string
  website: string
  facebook: string
  instagram: string
  avatar: string
  city_id: number
  direction_id: number
  status_id: number
  media: IMedia[]
  type?: 1 | 2
}

export type newSetting = Omit<
  Setting,
  "avatar" | "media" | "type" | "status_id"
>

type Media = {
  file: File
  collection_name: string
  model_id: number
  id: number
}

type SettingState = {
  list: Setting
  loading: boolean
  error: string | null
  postLoading: boolean
  postError: boolean
  mediaLoading: boolean
  mediaError: boolean
  status_id: number
}

export const fetchSettings = createAsyncThunk<
  Setting,
  string,
  { rejectValue: string }
>(
  "settings/fetchSettings",

  async function (_, { rejectWithValue }) {
    if (accessToken && salon_id) {
      const url = isRestaurant
        ? `${currentUrl}/master/restaurants/${salon_id}`
        : `${currentUrl}/master/salons/${salon_id}`

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })

     if (!response.ok) {
        return rejectWithValue("Ошибка при загрузке данных, попробуйте позже");
      }

      const data = await response.json()

      const processedData: Setting | Omit<Setting, "type"> = {
        id: data.id,
        name: data.name || "",
        surname: data.surname || "",
        phone: data.phone || "",
        deep_link: data.deep_link || "",
        description: data.description || "",
        street: data.street || "",
        building: data.building || "",
        website: data.website || "",
        facebook: data.facebook || "",
        instagram: data.instagram || "",
        avatar: data.avatar[data.avatar.length - 1]?.original_url || "",
        city_id: data.city_id || 0,
        direction_id: data.direction_id || 0,
        status_id: data.status_id,
        media: data.media.map(
          (item:IMedia): IMedia => ({
            original_url: item.original_url,
            collection_name: item.collection_name,
          })
        ),
        ...(isRestaurant ? {} : { type: data.salon_type.id || 0 }), // Exclude `type` for restaurants
      }

      return processedData
    }
    return rejectWithValue("Access token or salon ID missing");
  }
)

export const postSettings = createAsyncThunk<
  newSetting,
  newSetting,
  { rejectValue: boolean }
>(
  "settings/postSettings",
  async function ({ id, ...body }, { rejectWithValue }) {
    body.phone = body?.phone?.replace(/-/g, "").slice(-9)
    id = salon_id || id
    try {
      const url = isRestaurant
        ? `${currentUrl}/master/restaurants/${id}`
        : `${currentUrl}/master/salons/${id}`

      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        method: "PATCH",
        body: JSON.stringify(body),
      })

      if (!res.ok) {
        return rejectWithValue(true);
      }

      toast.success("Настройки изменены", { position: "bottom-right" })
      const data = await res.json()
      const processedData: newSetting = {
        id: data.id,
        name: data.name,
        surname: data.surname,
        deep_link: data.deep_link,
        phone: data.phone,
        description: data.description,
        street: data.street,
        building: data.building,
        website: data.website,
        facebook: data.facebook,
        instagram: data.instagram,
        city_id: data.city_id,
        direction_id: data.direction_id,
      }

      return processedData
    } catch (err) {
      console.error(err)
      toast.error("Ошибка при изменении настроек")
      return rejectWithValue(true);    }
  }
)

export const postMedia = createAsyncThunk<any, Media, { rejectValue: boolean }>(
  "settings/postMedia",
  async function ({ model_id, file, collection_name }, { rejectWithValue }) {
    try {
      const formData = new FormData()
      formData.append("file", file)
      formData.append("collection_name", collection_name)
      formData.append("model_id", String(model_id))
      model_id = salon_id || model_id

      const isRestaurant = user?.restaurant.direction_id === 1
      const url = isRestaurant
        ? `${currentUrl}/master/restaurants/${model_id}/update-image` 
        : `${currentUrl}/master/salons/${model_id}/update-image`
      const res = await fetch(
        // `${currentUrl}/master/salons/${model_id}/update-image` ,
        url,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          method: "POST",
          body: formData,
        }
      )

      return res.json()
    } catch (err) {
      rejectWithValue(true)
      toast.error("Ошибка при изменении медиафайлов")
    }
  }
)

const initialState: SettingState = {
  list: {
    id: 0,
    name: "",
    surname:"",
    phone: "",
    deep_link: "",
    description: "",
    street: "",
    building: "",
    website: "",
    facebook: "",
    instagram: "",
    avatar: "",
    city_id: 0,
    direction_id: 0,
    status_id: 0,
    media: [],
    type: 1,
  },
  status_id: 1,
  loading: false,
  error: null,
  postError: false,
  postLoading: false,
  mediaError: false,
  mediaLoading: false,
}

const settingsSlice = createSlice({
  name: "settingsSlice",
  initialState,
  reducers: {
    setStatusId(state, action) {
      state.status_id = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.loading = false
        state.list = action.payload
        console.log("Данные успешно получены:", action)
      })
      .addCase(fetchSettings.rejected, (state, action) => {
        state.loading = false
        state.error = typeof action.payload === "string" ? action.payload : null;
      })
      .addCase(postSettings.pending, (state) => {
        state.postError = false
        state.postLoading = true
      })
      .addCase(postSettings.fulfilled, (state) => {
        state.postLoading = false
      })
      .addCase(postSettings.rejected, (state) => {
        state.postError = true
        state.postLoading = false
      })
      .addCase(postMedia.pending, (state) => {
        state.mediaLoading = true
        state.mediaError = false
      })
      .addCase(postMedia.fulfilled, (state) => {
        state.mediaLoading = false
      })
      .addCase(postMedia.rejected, (state) => {
        state.mediaError = true
        state.mediaLoading = false
      })
  },
})

export const { setStatusId } = settingsSlice.actions

export default settingsSlice.reducer