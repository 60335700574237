import React from "react"
export function ContentBar({ mounted }) {
  return (
    <div
      className={`
          ${
            mounted ? "translate-x-0" : "translate-x-[-100%]"
          }  sm:block col-span-6 md:col-span-5 lg:col-span-4 xl:col-span-3
          sm:w-full w-[100vw]  flex  sm:px-[10px]    sm:pt-[60px]   transition-transform duration-500 bg-blue sm:h-screen h-[63px] sm:py-24  sm:overflow-hidden`}
    >
      <h1 className='sm:hidden mt-3 mx-16 box-content w-full text-3xl text-center font-semibold text-white '>
        Вход
      </h1>
      <div className=' hidden sm:flex sm:flex-1 gap-10 sm:flex-col sm:items-center'>
        <div className='mt-3  w-full text-3xl text-center font-semibold text-white'>
          <p className='text-[30px] font-[400] leading-[30px]'>
            Автоматизируйте{" "}
          </p>
          <p className='text-[30px] font-[400] leading-[30px]'>
            свой бизнес вместе с
          </p>
        </div>
        <img
          src='./assets/logo/logo.svg'
          alt='navbat'
          className='w-[282px] h-[44px] mt-[-20px]'
        />
        <img
          src='./assets/banner/newLogo.png'
          className='max-w-full mt-7'
          alt='content'
        />
      </div>
      {/* } hidden sm:flex sm:flex-col items-center */}
    </div>
  )
}