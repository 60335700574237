import React from "react"
import ReactInputMask from "react-input-mask"
import { forwardRef, useEffect, useState } from "react"
// import { parsePhoneNumberFromString } from "libphonenumber-js"

const countryMasks = {
  RU: "+7 (999) 999-99-99",
  KZ: "+7 (999) 999-99-99",
  KG: "+996 (999) 99-99-99",
  TJ: "+992 (999) 99-99-99",
  TM: "+993 (9) 99-99-99",
  UZ: "+998 (99) 999-99-99",
  US: "+1 (999) 999-9999",
  GB: "+44 9999 999999",
  DE: "+49 999 999999",
  FR: "+33 9 99 99 99 99",
  CN: "+86 999 9999 9999",
  IN: "+91 99999 99999",
  CA: "+1 (999) 999-9999",
  TR: "+90 (999) 999 99 99",
  AE: "+971 9 999 9999",
  BR: "+55 (99) 99999-9999",
  AR: "+54 9 9999-9999",
  JP: "+81 99-9999-9999",
  KR: "+82 99-9999-9999",
  MX: "+52 999 999 9999",
  EG: "+20 9 9999 9999",
  AU: "+61 9 9999 9999",
}

export const PhoneInput = forwardRef(
  (
    {
      value,
      onChange,
      disabled,
      error,
      autoFocus,
      className,
      name,
      onKeyDown,
      id,
    },
    ref
  ) => {
    const [maskedValue, setMaskedValue] = useState(value)
    // const [mask, setMask] = useState(countryMasks["TJ"]) // По умолчанию Таджикистан

    // useEffect(() => {
    //   if (!value) {
    //     setMaskedValue("") // Полностью очищаем инпут
    //     setMask("") // Убираем маску при очистке
    //     return
    //   }

    //   const phoneNumber = parsePhoneNumberFromString(value)
    //   if (phoneNumber) {
    //     const country = phoneNumber.country || "TJ"
    //     setMask(countryMasks[country] || countryMasks["TJ"])
    //   }
    //   setMaskedValue(value)
    // }, [value])

    // const handleChange = (e) => {
    //   const rawValue = e.target.value.replace(/\D/g, "") // Удаляем все нецифровые символы
    //   const phoneNumber = parsePhoneNumberFromString(`+${rawValue}`)

    //   if (phoneNumber) {
    //     const country = phoneNumber.country || "TJ"
    //     setMask(countryMasks[country] || countryMasks["TJ"])
    //   }

    //   setMaskedValue(e.target.value)
    //   if (onChange) {
    //     onChange({
    //       ...e,
    //       target: {
    //         ...e.target,
    //         value: rawValue, // Отправляем без маски
    //       },
    //     })
    //   }
    // }

    // В процессе разработки Маска

    useEffect(() => {
      if (value && value.startsWith("+992")) {
        setMaskedValue(value)
      } else {
        setMaskedValue(`+992${value}`)
      }
    }, [value])

    // // Обработчик изменения значения
    const handleChange = (e) => {
      setMaskedValue(e.target.value)
      if (onChange) {
        onChange(e)
      }
    }
    // const handleChange = (e) => {
    //   const rawValue = e.target.value.replace(/\D/g, "") // Remove all non-numeric characters
    //   const withoutPrefix = rawValue.startsWith("992")
    //     ? rawValue.substring(3)
    //     : rawValue
    //   setMaskedValue(`+992${withoutPrefix}`)
    //   if (onChange) {
    //     onChange({
    //       ...e,
    //       target: {
    //         ...e.target,
    //         value: withoutPrefix, // Send the value without the prefix
    //       },
    //     })
    //   }
    // }

    return (
      <>
        <ReactInputMask
          mask='+\9\92-999-999-999'
          // mask={mask}
          maskPlaceholder={null}
          placeholder='+992 Введите ваш номер телефона'
          name={name || "phone"}
          value={maskedValue}
          onChange={handleChange}
          onKeyDown={onKeyDown}
          disabled={disabled}
        >
          <input
            ref={ref}
            id={id}
            type='text'
            className={`inputOutlined w-full ${
              error && "border-red-500 focus:border-red-500 "
            } ${className}`}
          />
        </ReactInputMask>
        {error && <p className='text-red-500 text-sm mt-1'>{error}</p>}
      </>
    )
  }
)